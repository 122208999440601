// import store from '@/store';
import { postToItemServer,postToUserServer } from './common';

/**
 * 设计公司获取版权销售商品列表
 * @param {*} obj 
 */
export async function listPriceProduct(obj){
    let items = await postToItemServer('/designer/list-price-product',obj);
    return items;
}
/**
 * 设计公司版权购买确认收款
 * @param {*} obj 
 */
export async function qrPriceProduct(obj){
    let items = await postToItemServer('/designer/check-paid-price-product',obj);
    return items;
}
/**
 * 设计师版权销售上传新设计(旧)
 * @param {*} obj 
 */
export async function addPriceProduct(obj){
    return await postToItemServer('/designer/add-price-product',obj);
}
// 设计公司版权销售详情
export async function designPriceId(id){
    let item = postToItemServer('/designer/detail-price-product',{product_no:id});
    return item;
}
// 设计公司版权销售上下架
export async function togglePriceProduct(obj){
    return await postToItemServer('/designer/toggle-price-product',obj);
}
// 设计公司版权销售修改
export async function setPriceProduct(obj){
    return await postToItemServer('/designer/update-price-product',obj);
}
// 设计公司版权销售删除
export async function delPriceProduct(obj){
    return await postToItemServer('/designer/del-price-product',obj);
}
// 设计公司版权销售删除恢复
export async function undelPriceProduct(obj){
    return await postToItemServer('/designer/undel-price-product',obj);
}

/*---------------------------------------------------------------------------*
 * 设计作品库-新增或编辑单品作品
 * @param {*} obj 
 */
export async function addOrUpdateProduct(obj){
    return await postToItemServer('/designer/add-or-update-product',obj);
}
// 单品列表
export async function singleList(obj){
    return await postToItemServer('/designer/list-product',obj);
}
// 单品详情
export async function singleDetail(id){
    return await postToItemServer('/designer/detail-product',{ id: id });
}
// 删除商品
export async function trashProduct(ids){
    return await postToItemServer('/designer/trash-product',{ ids: ids });
}
// 恢复删除的商品
export async function untrashProduct(ids){
    return await postToItemServer('/designer/untrash-product',{ ids: ids });
}
// 彻底删除商品
export async function delProduct(ids){
    return await postToItemServer('/designer/del-product',{ ids: ids });
}
// 设计作品库-新增或编辑套系作品-生成套系编号
export async function initSetId(obj){
    return await postToItemServer('/designer/init-set-id',obj);
}
// 设计作品库-新增或编辑套系作品
export async function addOrUpdateSet(obj){
    return await postToItemServer('/designer/add-or-update-set',obj);
}
// 套系作品列表
export async function seriesList(obj){
    return await postToItemServer('/designer/list-set',obj);
}
// 套系作品详情
export async function seriesDetail(id){
    return await postToItemServer('/designer/detail-set',{ id: id });
}
// 编辑多个套系(排序)
export async function multiUpdateSet(rows){
    return await postToItemServer('/designer/multi-update-set',{ rows: rows });
}
// 删除套系
export async function trashSet(ids){
    return await postToItemServer('/designer/trash-set',{ ids: ids });
}
// 恢复删除的套系
export async function untrashSet(ids){
    return await postToItemServer('/designer/untrash-set',{ ids: ids });
}
// 彻底删除套系
export async function delSet(ids){
    return await postToItemServer('/designer/del-set',{ ids: ids });
}
// 删除套系商品
export async function delSetProduct(obj){
    return await postToItemServer('/designer/del-set-product', obj);
}

/*------------------------------订单列表------------------------------------*/
// 设计版权销售订单
export async function listProductOrder(obj){
    return await postToItemServer('/designer/list-product-order', obj);
}

/**
 * 设计公司获取分成合作商品列表
 * @param {*} obj 
 */
export async function listPercentProduct(obj){
    let items = await postToItemServer('/designer/list-percent-product',obj);
    return items;
}
/**
 * 设计师分成合作上传新设计
 * @param {*} obj 
 */
export async function addPercentProduct(obj){
    return await postToItemServer('/designer/add-percent-product',obj);
}
// 设计公司分成合作详情
export async function designPercentId(id){
    let item = await postToItemServer('/designer/detail-percent-product',{product_no:id});
    return item;
}
// 设计公司分成合作-终止合作
export async function endPercentProduct(obj){
    return await postToItemServer('/designer/end-percent-product',obj);
}
// 设计公司分成合作上下架
export async function togglePercentProduct(obj){
    return await postToItemServer('/designer/toggle-percent-product',obj);
}
// 设计公司分成合作修改
export async function setPercentProduct(obj){
    return await postToItemServer('/designer/update-percent-product',obj);
}
// 设计公司分成合作删除
export async function delPercentProduct(obj){
    return await postToItemServer('/designer/del-percent-product',obj);
}
// 设计公司分成合作删除恢复
export async function undelPercentProduct(obj){
    return await postToItemServer('/designer/undel-percent-product',obj);
}

/**
 * 获得统计指标数据
 */
export async function getStatistics(){
    return await postToItemServer('/designer/my-comp-statistics');
}
/**
 * 获取黑名单列表
 */
export async function getBlacklists(id,ret_type){
    let blacklists = await postToUserServer('/my-company/get-blacklists',{comp_id:id,ret_type:ret_type});
    return Object.values(blacklists);
}
/**
 * 加入黑名单
 * @param {*} obj 
 */
export async function addBlacklist(obj){
    return await postToItemServer('/my-company/add-blacklist',obj);
}
/**
 * 投诉工厂
 * @param {*} obj 
 */
export async function addComplain(obj){
    return await postToItemServer('/my-company/add-complain',obj);
}


